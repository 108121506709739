<template>
  <div>
    <listagem
      ref="listagem"
      :tela="permission"
      :headers="headers"
      :data="agendamentos"
      :dataTotal="dataTotal"
      :pageSize="page_size"
      @handleGet="handleGet"
    />
  </div>
</template>

<script>
import Listagem from '@/components/patio/GerenciamentoServico/listagem/Componente.vue'
import { mapActions, mapMutations, mapState } from 'vuex'
import { Sequences } from '@/utils/permissions'

export default {
  name: 'RelatorioRetornouOrigemListConcluidos',
  props: {
    headers: {
      type: Array,
      required: true,
    },
  },
  components: {
    Listagem,
  },
  data() {
    return {
      page_size: 15,
      dataTotal: 0,
    }
  },
  computed: {
    ...mapState('patio', ['agendamentos', 'regioes']),
    ...mapState('auth', ['empresaAtual', 'user']),
    ...mapState('relatorioRetornouOrigem', ['params']),
    permission() {
      return Sequences.RetornouOrigem.toString()
    },
  },
  methods: {
    ...mapMutations('patio', ['SET_AGENDAMENTOS']),
    ...mapActions('patio', [
      'getAllAgendamentosByParams',
      'getAgendamentoRelatorioOrigem',
      'getRelatorioServico',
      'getRegioesColaborator',
      'getRelatorioServicoDetalhado',
    ]),
    handleGet(event) {
      this.options = event
      this.page_size = event.itemsPerPage
      this.handleGetAgendamentos(event.page)
    },

    async handleGetAgendamentos(page = 1, new_page_size = undefined) {
      if (!Object.keys(this.params).length) return
      if (new_page_size) {
        this.page_size = new_page_size
      }
      const page_size = this.page_size

      this.$emit('loading')
      try {
        const { sortBy, sortDesc } = this.options
        let ordering
        if (
          sortBy &&
          sortBy.length === 1 &&
          sortDesc &&
          sortDesc.length === 1
        ) {
          ordering = sortBy[0]
          ordering = sortDesc[0] ? '-' + ordering : ordering
          ordering = ordering.replaceAll('.', '__')
        }
        if (
          this.empresaAtual.representantes.length > 0 &&
          this.params.regiao == undefined
        ) {
          await this.getRegioesColaborator()
          if (this.regioes.length > 0) {
            this.params.regiao = this.regioes[0].sigla
          }
        }
        const { results, count } = await this.getAgendamentoRelatorioOrigem({
          page,
          page_size: (page_size === -1 && this.dataTotal) || page_size,
          ordering,
          proprietario: this.empresaAtual.public_id,
          ...this.params,
        })
        this.data = results
        this.dataTotal = count
      } catch (e) {
        if (e.response.status === 404) {
          this.errorMessage('Página não encontrada')
        }
      } finally {
        this.$emit('loaded')
      }
    },
  },
}
</script>

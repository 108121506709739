<template>
  <v-row align="center" class="flex-column">
    <v-card-subtitle class="pb-0">{{ title }}</v-card-subtitle>
    <v-col class="pt-0">
      <v-row>
        <slot name="items" />
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'RelatorioRetornouOrigemListFiltroModalItem',
  props: {
    title: {
      type: String,
      required: true,
    },
  },
}
</script>

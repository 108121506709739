<template>
  <v-card class="pa-8">
    <v-overlay v-model="settingFilters">
      <v-progress-circular indeterminate size="32" />
    </v-overlay>
    <v-card-title
      v-if="areSettingsEmpty"
      class="error--text d-flex flex-column justify-center align-center"
    >
      <v-icon color="error">mdi-alert</v-icon>
      <span>Você não selecionou nenhum item nas configurações</span>
      <p class="primary--text">
        Clique no botão <v-icon>mdi-cog</v-icon> para configurar os filtros
      </p>
    </v-card-title>
    <validation-observer v-else-if="!settingFilters" v-slot="{ invalid }">
      <v-card-subtitle class="error--text"
        >Todos os campos abaixo são obrigatórios</v-card-subtitle
      >
      <v-card-text>
        <relatorio-retornou-origem-list-filtro-modal-item
          title="Gate"
          v-if="!empresaAtual.gate"
        >
          <template #items>
            <v-col>
              <validation-provider
                name="gate"
                rules="required"
                v-slot="{ errors }"
              >
                <v-autocomplete
                  v-model="params.gate.value"
                  label="Gate"
                  prepend-icon="mdi-domain"
                  :items="gates"
                  :item-text="(item) => `${item.business_name} - ${item.cnpj}`"
                  item-value="public_id"
                  :error-messages="errors"
                />
              </validation-provider>
            </v-col>
          </template>
        </relatorio-retornou-origem-list-filtro-modal-item>
        <relatorio-retornou-origem-list-filtro-modal-item
          title="Data"
          v-if="showData"
        >
          <template #items>
            <v-col v-if="params.data_inicio.visible">
              <validation-provider
                name="data início"
                v-slot="{ errors }"
                rules="required"
              >
                <conferencia-list-filtro-data
                  propLabel="De"
                  v-model="params.data_inicio.value"
                  :errors="errors"
                />
              </validation-provider>
            </v-col>
            <v-col v-if="params.data_fim.visible">
              <validation-provider
                name="data fim"
                v-slot="{ errors }"
                rules="required"
              >
                <conferencia-list-filtro-data
                  propLabel="Até"
                  v-model="params.data_fim.value"
                  :errors="errors"
                />
              </validation-provider>
            </v-col>
          </template>
        </relatorio-retornou-origem-list-filtro-modal-item>

        <relatorio-retornou-origem-list-filtro-modal-item
          title="Fornecedor"
          v-if="showFornecedor"
        >
          <template #items>
            <relatorio-retornou-origem-list-filtro-modal-item-input
              v-if="params.fornecedor.visible"
              v-model="params.fornecedor.value"
              name="fornecedor"
              label="Fornecedor (CNPJ)"
              icon="mdi-account-child"
              rules="cnpjValido|required"
              mask="##.###.###/####-##"
            />
            <relatorio-retornou-origem-list-filtro-modal-item-input
              v-if="params.produto.visible"
              v-model="params.produto.value"
              name="produto"
              label="Produto"
              icon="mdi-corn"
              rules="required"
            />
            <relatorio-retornou-origem-list-filtro-modal-item-input
              v-if="params.sub_produto.visible"
              v-model="params.sub_produto.value"
              name="sub produto"
              label="Sub Produto"
              icon="mdi-corn"
              rules="required"
            />
          </template>
        </relatorio-retornou-origem-list-filtro-modal-item>

        <relatorio-retornou-origem-list-filtro-modal-item
          title="Contratos"
          v-if="showContratos"
        >
          <template #items>
            <relatorio-retornou-origem-list-filtro-modal-item-input
              v-if="params.terminal.visible"
              v-model="params.terminal.value"
              name="terminal"
              label="Terminal (CNPJ)"
              icon="mdi-mailbox"
              rules="cnpjValido|required"
              mask="##.###.###/####-##"
            />
            <relatorio-retornou-origem-list-filtro-modal-item-input
              v-if="params.cliente.visible"
              v-model="params.cliente.value"
              name="cliente"
              label="Cliente (CNPJ)"
              icon="mdi-mailbox"
              rules="cnpjValido|required"
              mask="##.###.###/####-##"
            />
            <relatorio-retornou-origem-list-filtro-modal-item-input
              v-if="params.transportadora.visible"
              v-model="params.transportadora.value"
              name="transportadora"
              label="Transportadora (CNPJ)"
              icon="mdi-bag-carry-on"
              rules="cnpjValido|required"
              mask="##.###.###/####-##"
            />
          </template>
        </relatorio-retornou-origem-list-filtro-modal-item>

        <relatorio-retornou-origem-list-filtro-modal-item
          title="Região"
          v-if="showRegiao"
        >
          <template #items>
            <relatorio-retornou-origem-list-filtro-modal-item-input
              v-if="params.regiao.visible"
              v-model="params.regiao.value"
              name="regiao"
              label="Região"
              icon="mdi-home-city"
              rules="required"
            />
            <relatorio-retornou-origem-list-filtro-modal-item-input
              v-if="params.representante.visible"
              v-model="params.representante.value"
              name="representante"
              label="Representante (CPF)"
              icon="mdi-face-agent"
              rules="cpfValido|required"
              mask="###.###.###-##"
            />
          </template>
        </relatorio-retornou-origem-list-filtro-modal-item>

        <relatorio-retornou-origem-list-filtro-modal-item
          title="Veículo"
          v-if="showVeiculo"
        >
          <template #items>
            <relatorio-retornou-origem-list-filtro-modal-item-input
              v-if="params.motorista.visible"
              v-model="params.motorista.value"
              name="motorista"
              label="Motorista (CPF)"
              icon="mdi-card-account-details"
              rules="cpfValido|required"
              mask="###.###.###-##"
            />
            <relatorio-retornou-origem-list-filtro-modal-item-input
              v-if="params.placa_cavalo.visible"
              v-model="params.placa_cavalo.value"
              name="placa cavalo"
              label="Placa Cavalo"
              icon="mdi-truck"
              mask="AAA-XXXX"
              :rules="{
                min: 8,
                required: true,
              }"
            />
            <relatorio-retornou-origem-list-filtro-modal-item-input
              v-if="params.eixo.visible"
              v-model="params.eixo.value"
              name="eixo"
              label="Eixo"
              icon="mdi-truck"
              :rules="{
                min_value: 1,
                required: true,
              }"
            />
          </template>
        </relatorio-retornou-origem-list-filtro-modal-item>
      </v-card-text>
      <v-card-actions class="d-flex justify-space-between">
        <v-btn plain @click="$emit('close')">Cancelar</v-btn>
        <v-btn
          class="px-8"
          x-large
          color="primary"
          @click="search"
          :disabled="areSettingsEmpty || invalid"
          ><v-icon>mdi-database-search</v-icon>
          <span>Buscar</span>
        </v-btn>
      </v-card-actions>
    </validation-observer>
  </v-card>
</template>

<script>
import ContratoAPI from '@/services/tenants/contratos'

import { data } from './_data'
import { computed } from './_computed'
import { methods } from './_methods'

import ConferenciaListFiltroData from '@/components/financeiro/conferencia/conferencia-list/ConferenciaListFiltroData'
import RelatorioRetornouOrigemListFiltroModalItem from '../RelatorioRetornouOrigemListFiltroModalItem.vue'
import RelatorioRetornouOrigemListFiltroModalItemInput from '../RelatorioRetornouOrigemListFiltroModalItemInput.vue'

export default {
  name: 'RelatorioRetornouOrigemListFiltroModal',
  components: {
    ConferenciaListFiltroData,
    RelatorioRetornouOrigemListFiltroModalItem,
    RelatorioRetornouOrigemListFiltroModalItemInput,
  },
  data,
  computed,
  methods,
  async created() {
    this.settingFilters = true

    if (this.empresaAtual.gate) {
      this.params.gate.value = this.empresaAtual.public_id
    } else {
      const { possible_gates } = await ContratoAPI.getPossiveisGates(
        this.empresaAtual.public_id
      )
      this.gates = possible_gates
      this.params.proprietario.value = this.empresaAtual.public_id
    }

    await this.showOnlySelectedOptionsAndGetItems()
    this.settingFilters = false
  },
}
</script>
